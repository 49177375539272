<div class="row">
  <div class="col-md-8">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-md-12">
          <h4 class="card-title text-muted wrap-this" style="margin-bottom:0">Included Drivers</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)"
              class="wrap-this"
             (click)="excludeAll()">
            Exclude All
          </a>
        </div>
      </div>
      <div class="row" style="padding-top:1rem">
        <div class="col-md-12">
          <div>
            <app-data-table
              #driverTable
              [columns]="driverTableConfig.fields"
              [data]="driverTableConfig.data"
              [showPagination]="driverTableConfig?.showPagination"
              [showPageSize]="true"
              [customPageSize]="10"
              [noRecordsLabel]="driverTableConfig?.noRecordsLabel"
              (triggerEvent)="triggerDriver($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-md-12">
          <h4 class="card-title text-muted wrap-this" style="margin-bottom:0">Excluded Drivers</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a href="javascript:void(0)"
              class="wrap-this"
             (click)="includeAll()">
            Include All
          </a>
        </div>
      </div>
      <div class="row" style="padding-top:1rem">
        <div class="col-md-12">
          <div>
            <app-data-table
              #driverExcludedTable
              [columns]="driverExcludedTableConfig?.fields"
              [data]="driverExcludedTableConfig?.data"
              [showPagination]="driverExcludedTableConfig?.showPagination"
              [showPageSize]="true"
              [customPageSize]="10"
              [noRecordsLabel]="driverExcludedTableConfig?.noRecordsLabel"
              (triggerEvent)="triggerDriverExcluded($event)"
            ></app-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

