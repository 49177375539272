import { AfterViewInit, OnInit, Component, Input, Output, ViewChild, EventEmitter, TrackByFunction } from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { animate, state, style, transition, trigger } from '@angular/animations';

export interface TableConfig {
  columns: TableColumn[];
  showPagination?: boolean;
  noRecordsLabel?: string;
  expandable?: boolean;
  expand?: string;
  withFooter?: boolean;
  footerObject?: any;
  rowClassObject?: any;
}

export interface TableColumn {
  name: string;
  title: string;
  type?: string;
  sort?: boolean;
  hidden?: boolean;
  clickable?: boolean;
  component?: string;
  componentObject?: any;
  render?: Function;
  click?: Function;
  rowClass?: any;
  columnClass?: any;
}

export interface TablePaginatorConfig {
  length?: number;
  pageSize?: number;
  pageIndex?: number;
  pageSizeOptions: number[];
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed,void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[];
  tableConfig: TableConfig;
  paginatorConfig: TablePaginatorConfig;
  pageSize = 50;
  pageSizeOptions: Array<number> = [10, 25, 50, 100, 500];
  pageEvent: PageEvent;

  dataSource: MatTableDataSource<any> ;
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  expandedRow: any;
  
  @Input() columns: any[];
  @Input() set data(value:any[]) {
    this.dataSource = new MatTableDataSource<any>(value)
  }
  @Input() noRecordsLabel: string;
  @Input() showPagination: boolean;
  @Input() showPageSize: number;
  @Input() expandable: boolean;
  @Input() expand: any;
  @Input() withFooter: boolean;
  @Input() footerObject: any;
  @Input() rowClassObject: any;
  @Input() customPageSize: number;
 // @Input() tableConfig: TableConfig;

  @Output() triggerOpenModal = new EventEmitter<any>();
  @Output() triggerNavigateDriverProfile = new EventEmitter<string>();
  @Output() triggerSubmitAttempt = new EventEmitter<any>();
  @Output() triggerAction = new EventEmitter<any>();
  @Output() triggerDownload = new EventEmitter<any>();
  @Output() triggerNavigate = new EventEmitter<any>();
  @Output() triggerSelectChange = new EventEmitter<any>();
  @Output() triggerEvent = new EventEmitter<any>();
  @Output() triggerSortChange = new EventEmitter<any>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<any>;

  @Input() totalItems: number;
  currentPage = 1;

  identity: TrackByFunction<any> = (_, item: any) => item.id;

  constructor() { }

  ngOnInit() {
    this.setTableConfig();
    this.setPaginatorConfig();

    this.dataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
      if (typeof data[sortHeaderId] === 'string') {
        return data[sortHeaderId].toLocaleLowerCase();
      }
    
      return data[sortHeaderId];
    };
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  render(column: any, row: any): any {
    let output;
    if (column?.render) {
      output = column?.render(row);
    } else {
      output = row[column.name];
    }
    
    return output === null ? '' : output;
  }

  onClick(column: any, row: any): void {
    if (column?.click && typeof(column?.click) === 'function') {
      return column?.click(row);
    }
  }

  private setTableConfig(): void {
    const columns = [];
    const displayedColumns = [];

    if (this.columns?.length) {
      this.columns.forEach( (column) => {
        const tableColumn: TableColumn = {
          type: column?.type,
          name: column?.objectKey,
          title: column?.name,
          sort: typeof(column.sort) === 'undefined' ? true : column?.sort || false,
          hidden: column?.hidden,
          clickable: column?.columnClass === 'clickable' || column?.clickable,
          component: column?.component || null,
          componentObject: column?.componentObject || null,
          render: column?.render || null,
          click: column?.click || null,
          rowClass: column?.rowClass || null
        };
        if (!(this.expand && column?.objectKey === 'details')) {
          columns.push(tableColumn);
        }
        if (!tableColumn?.hidden) {
          displayedColumns.push(column?.objectKey);
        }
      });
    }

    // Set table config
    const tableConfig: TableConfig = {
      columns: columns,
      noRecordsLabel: this.noRecordsLabel || 'No records found.',
      showPagination: this.showPagination || false,
      expandable: this.expandable || false,
      expand: this.expand || null,
      withFooter: this.withFooter || false,
      footerObject: this.footerObject || null,
      rowClassObject: this.rowClassObject || null
    }

    this.tableConfig = tableConfig;

    // Set displayed columns
    this.displayedColumns = displayedColumns;
  }

  private setPaginatorConfig(): void {
    const paginatorConfig: TablePaginatorConfig = {
      pageSize: this.customPageSize || this.pageSize || 50,
      pageSizeOptions: [10, 25, 50, 100, 500]
    };
    this.paginatorConfig = paginatorConfig;
  }

  getTotals(columnName: string) {
    return this.dataSource?.data.map(d => d[columnName]).reduce((acc, value) => acc + value, 0);
  }

  getRowClass(row: any): string {
    const rowClass = this.tableConfig?.columns[0]?.rowClass;
    if (typeof(rowClass) === 'function') {
      return rowClass(row);
    }
    return rowClass;
  }

  addRows(newData: any[]) {
    if (newData?.length) {
      this.dataSource.data = [ ...this.dataSource.data, ...newData ];
      this.table.renderRows();
    }
  }

  deleteRow(name: string, value: any) {
    const data = this.dataSource.data;
    const index = data?.findIndex((d) => { 
      return d[name] === value 
    });
    data.splice(
      this.paginator.pageIndex * this.paginator.pageSize + index,
      1
    );
    this.dataSource.data = data;
    this.table.renderRows();
  }

  changeRowLength(value: number): void {
    this.paginatorConfig.pageSize = value;
    this.table.renderRows();
  }

  onSortChange(sortState: Sort) {
    this.triggerSortChange.emit(sortState);
  }

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageSize = e.pageSize;
    this.table.renderRows();
  }
}
