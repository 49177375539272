<quick-search-menu></quick-search-menu>
<div class="row" style="padding-bottom: 20px">
  <div class="col-md-6">
    <h3>Orders & Assignments</h3>
  </div>
  <div class="col-md-6">
    <div style="float: right; margin-right: 5px">
      <button
        type="button"
        class="btn btn-primary btn-next-step"
        [disabled]="
          (!this.safetyProcessesForm.valid && !this.trainingAssignmentsForm.valid) || 
          (this.safetyProcessesForm.pristine && this.trainingAssignmentsForm.pristine)
        "
        (click)="launchSubmitBatchModal()"
      >
      Review Orders & Assignments
      </button>
    </div>
  </div>
  <div class="col-md-6" *ngIf="isSubmitted">
    <h3 class="label-validation-error">SUBMITTED</h3>
  </div>
</div>
<div class="row">
  <div class="col-md-12">
    <mat-stepper #stepper>
      <mat-step *ngIf="this.processesTabProceses.size > 0" >
        <ng-template matStepLabel>
          <div (click)="stepChange($event)">Select Criteria for MVRs</div>
        </ng-template>
        <app-multiple-services-tab
          [safetyProcesses]="processesTabProceses"
          [parentForm]="safetyProcessesForm"
          [formGroupNameInput]="multipleServicesFormGroupName">
        </app-multiple-services-tab>
      </mat-step>
      <mat-step *ngIf="this.processesTabProceses.size > 0">
        <ng-template matStepLabel>Select Drivers for MVRs</ng-template>
        <app-select-drivers-tab
          [parentForm]="safetyProcessesForm"
          selectorPrefix="MvrDrivers"
          [criteriaFormGroupName]="multipleServicesFormGroupName">
        </app-select-drivers-tab>
      </mat-step>
      <mat-step *ngIf="this.trainingTabProcesses.size > 0">
        <ng-template matStepLabel>Select Criteria for Training</ng-template>
        <app-assign-training-tab
          [safetyProcesses]="trainingTabProcesses"
          [parentForm]="trainingAssignmentsForm"
          [formGroupNameInput]="trainingFormGroupName">
        </app-assign-training-tab>
      </mat-step>
      <mat-step *ngIf="this.trainingTabProcesses.size > 0" [stepControl]="trainingAssignmentsForm.controls[trainingFormGroupName]">
        <ng-template matStepLabel>Select Drivers for Training</ng-template>
        <app-select-drivers-tab
          [parentForm]="trainingAssignmentsForm"
          selectorPrefix="TrainingDrivers"
          [criteriaFormGroupName]="trainingFormGroupName">
        </app-select-drivers-tab>
      </mat-step>
    </mat-stepper>
  </div>
</div>
