//Angular
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
//Third Party
import { Subscription } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
//App
import { ReviewSubmitModalComponent } from './review-submit-modal/review-submit-modal.component';
import { SafetyProcess, SafetyProcessesService, SafetyProcessList } from './services/safety-processes.service';
import { LineOfBusinessService } from '../../components/line-of-business-service/line-of-business-service.component';
import { SubmitBatchService } from '../shared/batch-assignment/submit-batch.service';
import { Batch } from '../shared/batch-assignment/batch';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { DriverTrainingCourse } from '../../components/classes-and-interfaces/classes-and-interfaces.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { AlertService } from '../../services/alert-service/alert.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-orders-and-assignments',
  templateUrl: './orders-and-assignments.component.html',
  styleUrls: ['./orders-and-assignments.component.scss']
})
export class OrdersAndAssignmentsComponent implements OnInit, OnDestroy {
  @ViewChild("stepper") stepper: MatStepper;
  private _safetyProcesses: Map<SafetyProcessList, SafetyProcess>;
  private _safetyClientSelected: string;
  private readonly _subscriptions: Array<Subscription>;
  private readonly _safetyProcessesLoaded = false;

  private _processesTabApplicableProcesses: string[] = Object.values(SafetyProcessList)
    .filter(process => process !== SafetyProcessList.Training && SafetyProcessList.Telematics);

  private _trainingTabApplicableProcesses: string[] = [SafetyProcessList.Training, SafetyProcessList.Telematics];

  public processesTabProceses = new Map<SafetyProcessList, SafetyProcess>();
  public trainingTabProcesses = new Map<SafetyProcessList, SafetyProcess>();

  public processesTabHeaders: TabHeader;
  public trainingTabHeaders: TabHeader;
  public trainingAssignmentsForm: UntypedFormGroup = new UntypedFormGroup({});
  public safetyProcessesForm: UntypedFormGroup = new UntypedFormGroup({});
  public nbrDrivers: number;

  public multipleServicesFormGroupName = 'multipleServicesForm';
  public trainingFormGroupName = 'trainingLessonsForm';

  public isSubmitted = false;
  selectedIndex = 0;

  constructor(
    private _lineOfBusinessService: LineOfBusinessService,
    private readonly _safetyProcessService: SafetyProcessesService,
    private _submitBatchService: SubmitBatchService,
    private loadingSpinnerService: LoadingSpinnerService,
    private alertService: AlertService,
    private _routerService: Router,
    private _errorService: ErrorModalService,
    private dialog: MatDialog,
  ) {

    this._subscriptions = new Array<Subscription>();
  }



  ngOnInit(): void {

    // set line of business to safety if not already initialized to Safety
    const lob = this._lineOfBusinessService.getLineOfBusinessValue();

    if (lob !== 2) {
      this._lineOfBusinessService.setLineOfBusiness(2);
    }

    this._subscriptions.push(this._safetyProcessService.safetyProcesses$.subscribe((data) => this.safetyProcessesServiceHandler(data)));

  }

  safetyProcessesServiceHandler(data: Map<SafetyProcessList, SafetyProcess>) {

    this._safetyProcesses = data;
    this._safetyProcesses.forEach((value, key) => {
      if (this._processesTabApplicableProcesses.includes(key)) {
        this.processesTabProceses.set(key, value);
      } else if (
        this._trainingTabApplicableProcesses.includes(key)) {
        this.trainingTabProcesses.set(key, value);
      }

    });
    this._safetyClientSelected = this._safetyProcessService.safetyClientSelected;
    let currentTab = 1;
    if (this.processesTabProceses.size > 0) {
      this.processesTabHeaders = {
        criteriaHeaderHtml: this.buildTabHeader(currentTab, 'Select Criteria for MVRs'),
        driverSelectionHeaderHtml: this.buildTabHeader(currentTab + 1, 'Select Drivers for MVRs')
      };
      currentTab += 2;
    }
    if (this.trainingTabProcesses.size > 0) {
      this.trainingTabHeaders = {
        criteriaHeaderHtml: this.buildTabHeader(currentTab, 'Select Criteria for Training'),
        driverSelectionHeaderHtml: this.buildTabHeader(currentTab + 1, 'Select Drivers for Training')
      };
      currentTab += 2;
    }
  }

  // mdbootstrap tabs require the header to be defined as a string to be passed into the component as an argument.
  private buildTabHeader(tabNumber: number, headerLabel: string) {
    let output = '';
    output += '<div class="headingNumber"><div class="headingNumberAlign">' + tabNumber.toString() + '</div></div>';
    output += '<div class="heading">' + headerLabel + '</div>';
    return output;
  }

  launchSubmitBatchModal() {
    const selectedDriversForm = this.safetyProcessesForm.controls['selectedDrivers'] as UntypedFormGroup;
    this.nbrDrivers = selectedDriversForm.controls['includedDrivers']?.value?.length;
    const dialogRef = this.dialog.open(ReviewSubmitModalComponent, {
      data: { prompt: `You have ${this.nbrDrivers} drivers selected. Are you sure you want to submit this batch?` },
      width: '50em',
      minHeight: '13em',
      panelClass: 'alert-modal',
      hasBackdrop: false,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) this.submitBatch();
    })
  }

  submitBatch() {
    this.loadingSpinnerService.show();
    const batchData: Batch = { ClientCode: this._safetyClientSelected };
    if (this.safetyProcessesForm.valid) {
      const multipleServicesForm = this.safetyProcessesForm.controls[this.multipleServicesFormGroupName] as UntypedFormGroup;
      const selectedProcessesFg = multipleServicesForm.controls['servicesSelections'] as UntypedFormGroup;
      const selectedDriversForm = this.safetyProcessesForm.controls['selectedDrivers'] as UntypedFormGroup;
      const multipleServicesIncludedDrivers = selectedDriversForm.controls['includedDrivers'].value as Array<number>;
      const multipleServicesExcludedDrivers = selectedDriversForm.controls['excludedDrivers'].value as Array<number>;
      const includedDriversStr = multipleServicesIncludedDrivers.join(',');
      const excludedDriversStr = multipleServicesExcludedDrivers.join(',');

      if (
        selectedProcessesFg.controls[SafetyProcessList.Mvr].value === true ||
        selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation].value === true
      ) {
        batchData.MvrDriverIdList = includedDriversStr;
        batchData.MvrDriverIdExcludeList = excludedDriversStr;
        batchData.MvrDataValidationOnly =
          (selectedProcessesFg.controls[SafetyProcessList.Mvr].value === true) ? false : true;

        if (selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation].value === true) {
          batchData.MvrDataValidationDue =
            new Date(selectedProcessesFg.controls[SafetyProcessList.LicenseVerifcation + 'dd'].value)
              .toLocaleDateString('en-US');
        }

        if (selectedProcessesFg.controls[SafetyProcessList.CertificateOfViolation].value === true) {
          batchData.ProcessId = 2;
          batchData.IsCOVRequested = true;
        }
      }

      if (selectedProcessesFg.controls[SafetyProcessList.Monitoring].value === true) {
        batchData.MvrMonitoringDriverIdList = includedDriversStr;
        batchData.MvrMonitoringDriverIdExcludeList = excludedDriversStr;
      }

      if (selectedProcessesFg.controls[SafetyProcessList.AutoCoverage].value === true) {
        batchData.CertificateOfInsuranceDriverIdList = includedDriversStr;
        batchData.CertificateOfInsuranceDriverIdExcludeList = excludedDriversStr;
      }

      if (selectedProcessesFg.controls[SafetyProcessList.PolicySignOff].value === true) {
        batchData.PolicySignoffDriverIdList = includedDriversStr;
        batchData.PolicySignoffDueDate =
          new Date(selectedProcessesFg.controls[SafetyProcessList.PolicySignOff + 'dd'].value)
            .toLocaleDateString('en-US');
      }

      if (selectedProcessesFg.controls[SafetyProcessList.LicenseUpload].value === true) {
        batchData.LicenseUploadDriverIdList = includedDriversStr;
        batchData.LicenseUploadDriverIdExcludeList = excludedDriversStr;
        batchData.LicenseUploadDueDate =
          new Date(selectedProcessesFg.controls[SafetyProcessList.LicenseUpload + 'dd'].value)
            .toLocaleDateString('en-US');
      }

      if (selectedProcessesFg.controls[SafetyProcessList.DriverQualification].value === true) {
        batchData.EnrollInDQService = true;
        batchData.DQDriverIdList = includedDriversStr;
        batchData.IsDQMedicalCardRequested = selectedProcessesFg.controls[SafetyProcessList.DqMedCard].value;
        batchData.IsDQDriversLicenseUploadRequested = selectedProcessesFg.controls[SafetyProcessList.DqLicenseUpload].value;
        batchData.IsDQDriverApplicationRequested = selectedProcessesFg.controls[SafetyProcessList.DqDriverApplication].value;
        batchData.IsDQCOVRequested = selectedProcessesFg.controls[SafetyProcessList.DqCertificateOfViolation].value;
        batchData.IsDQMVRRequested = selectedProcessesFg.controls[SafetyProcessList.Mvr].value;
        batchData.IsDQRecordOfRoadTestRequested = selectedProcessesFg.controls[SafetyProcessList.DqRecordOfRoadTest].value;
        batchData.IsDQCertificateOfRoadTestRequested = selectedProcessesFg.controls[SafetyProcessList.DqCertificateOfRoadTest].value;
        batchData.IsDQDriversLogRequested = selectedProcessesFg.controls[SafetyProcessList.DqDriverLog].value;
        batchData.IsDQClearingHouseLimitedSignoffRequested = selectedProcessesFg.controls[SafetyProcessList.DqClearingHouse].value;
      }

    }

    if (this.trainingAssignmentsForm.valid) {
      const trainingDriversForm = this.trainingAssignmentsForm.controls['selectedDrivers'] as UntypedFormGroup;
      const selectedTrainingLessonsForm = this.trainingAssignmentsForm.controls[this.trainingFormGroupName] as UntypedFormGroup;
      const safetyServicesSelections = selectedTrainingLessonsForm.controls['servicesSelections'] as UntypedFormGroup;
      const trainingLessonsForm = selectedTrainingLessonsForm.controls['trainingSelections'] as UntypedFormGroup;
      const trainingLessonsFormArray = trainingLessonsForm.controls['selectedLessons'] as UntypedFormArray;
      const trainingIncludedDrivers = trainingDriversForm.controls['includedDrivers'].value as Array<number>;
      const trainingExcludedDrivers = trainingDriversForm.controls['excludedDrivers'].value as Array<number>;


      batchData.TrainingDriverIdList = trainingIncludedDrivers.join(',');
      batchData.TrainingDriverIdExcludeList = trainingExcludedDrivers.join(',');

      const selectedLessons = trainingLessonsFormArray.controls
        .filter((cont: UntypedFormGroup) => cont.controls['selected'].value)
        .map((cont: UntypedFormGroup) => {
          return cont.controls['lesson'].value as DriverTrainingCourse;
        });

      batchData.TrainingCourseGroupList = selectedLessons.map(lesson => lesson.courseGroupId).join(',');
      batchData.TrainingPassRateList = selectedLessons.map(lesson => lesson.passRate).join(',');
      batchData.TrainingDueDate = new Date(safetyServicesSelections.controls[SafetyProcessList.Training + 'dd'].value)
        .toLocaleDateString('en-US');

    }

    this._submitBatchService.submitBatch(batchData)
      .subscribe({
        next: (result) => {
          this.loadingSpinnerService.hide();
          this.isSubmitted = true;
          this.alertService.showSuccessAlert('Your request has been submitted.', 'end', 'top', 5000);
          this._routerService.navigate(['/statcenter/', 2]);
        },
        error: (err: HttpErrorResponse) => {
          this._errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      });
  }
  /*
  export interface Batch {

    TrainingAssignmentType?: number;

}
*/

  ngOnDestroy(): void {

    this._subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  // ngAfterViewChecked(): void {
  //   if (this._safetyProcessesLoaded !== true) {
  //     if (this.tabset.tabs.length > 0) {
  //       this._safetyProcessesLoaded = true;
  //       this.tabset.setActiveTab(1);

  //     }
  //   }

  // }

  onGetActiveTab(event) {
    if (event != null && 'activeTabIndex' in event) {
      //const activeTab = event.activeTabIndex;
    }
    /*
    this.tabState = {
      currentTab: event.
    }
    */
  }


}


interface TabHeader {
  criteriaHeaderHtml: string;
  driverSelectionHeaderHtml: string;
}
