import { Pipe, PipeTransform } from '@angular/core';
import { GridsterItem } from 'angular-gridster2';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

export interface LineOfBusinessInfo {
  lineOfBusinessId: number;
  lineOfBusinessName: string;
}

export interface PowerBITile {
  reportGuid: string;
  token: string;
  embedUrl: string;
  visualizationId?: string;
  pageName?: string;
  // following are not returned from service; are added in ts code
  type: string;
  tokenType: string;
}

export interface DashboardTile {
  userId: string;
  dataGridTitle: string;
  dataGridDescription: string;
  positionX: number;
  positionY: number;
  tileId: number;
  title: string;
  description: string;
  tileType: string;
  isActive: boolean;
  tileHeight: number;
  tileWidth: number;
  dateFilterColumn: string;
  dateFilterTable: string;
  clientFilterColumn: string;
  clientFilterTable: string;
  dataGridId: number;
  visualizationId: string;
  pageName: string;
  lineOfBusinessId: number;
  reportGuid: string;
  token: string;
  embedUrl: string;
  filterStartDate: string;
  filterEndDate: string;
  tileDateFilterType: string;
  tileDateFilterTypeId: number;
  hasSlicer: boolean;
  slicerDisplayName: string;
  slicerPbixFieldName: string;
  slicerPbixTableName: string;
  slicerOptions: string;
  slicerDefaultValue: string;
  slicerOperator: string;
  jsChartTileConfig: string;
  jsChartTypeId: string;
  categoryIdList: string;

  // following are not returned from service; are added in ts code
  type: string;
  tokenType: string;
  filtersSummary: Array<string>;
  clientSelectedArray: Array<string>;
  globalDateFilter: DashboardDateFilterRange;
  isDropTarget: boolean;
  //reportLoaded: boolean;
  slicerOptionChosen: string;
  isOnPrem: boolean;
  onPremReportId: number;
  onPremApiUrl: string;
}

export interface DashboardTileCategory {
  categoryId: number;
  categoryName: string;
  // following is not returned from service; used for checkbox selection
  selected: boolean;
}

export interface DashboardTileFilterInfo {
  filterText: string;
}

export interface DashboardGridsterItem extends GridsterItem {
  // has all GridsterItem properties, plus Dashboard tile object
  dashboardTile: DashboardTile;
}
export interface DashboardFilterRecord {
  clientId: string;
  userClientGroupFilterId: number;
  reqDataElementId: number;
  element: string;
  filterOperatorId: number;
  filterOperator: string;
  filterValue: string;
  isActive: boolean;
  isUserEditable: boolean;
  clientName: string;
}
export interface PowerBIToken {
  title: string;
  token: string;
  embedUrl: string;
  reportId: string;
  type: string;
  tokenType: string;
}

export interface ClientSelectionObject {
  clientId: number,
  clientName: string;
  clientShortName: string;
  logoFileName: string;
  productId: number;
  productName: string;
  checked?: boolean;
  value: string;
}

export class WidgetInfo {
  tileId: number;
  title: string;
  description: string;
  tileType: string;
  isActive: boolean;
  isDefault: boolean;
  tileHeight: number;
  tileHeightString: string;
  tileWidth: number;
  tileWidthString: string;
  dateFilterColumn: string;
  dateFilterTable: string;
  clientFilterColumn: string;
  clientFilterTable: string;
  dataGridId: number;
  dataGridIdString: string;
  reportGuid: string;
  visualizationId: string;
  pageName: string;
  lineOfBusinessId: number;
  lineOfBusinessIdString: string;
  tileDateFilterTypeId: number;
  tileDateFilterTypeIdString: string;
  hasSlicer: boolean;
  slicerDisplayName: string;
  slicerPbixFieldName: string;
  slicerPbixTableName: string;
  slicerOptions: string;
  slicerDefaultValue: string;
  slicerOperator: string;
  // added for highcharts
  jsChartDataStoredProc: string;
  jsChartTileConfig: string;
  jsChartTypeId: number;
  jsChartTypeIdString: string;
  categoryIdList: string;
  onPremReportId: number;
  onPremApiUrl: string;
  isOnPrem: boolean;
  constructor() {
    // defaults for new widgets
    this.isActive = false;
    this.isDefault = false;
    this.tileWidth = 1;
    this.tileWidthString = '1';
    this.tileHeight = 1;
    this.tileHeightString = '1';
    this.tileDateFilterTypeId = 3;
    this.tileDateFilterTypeIdString = '3';
    this.lineOfBusinessIdString = '';
    this.dataGridIdString = '';
    this.categoryIdList = '';
    this.hasSlicer = false;
    this.isOnPrem = false;
    this.onPremReportId = null;
    this.onPremApiUrl = '';
  }
}

export class WidgetPerClientSettingsInfo {
  clientId: number;
  clientName: string;
  clientShortName: string;
  productId: number;
  productName: string;
  isActive: boolean;
}

export class DataGridInfo {
  dataGridId: number;
  title: string;
  description: string;
  dataSourceSql: string;
  showFilterSw: boolean;
  isInNavMenu: boolean;
  isDefault: boolean;
  lineOfBusinessId: number;
  lineOfBusinessIdString: string;
  dateFiltlerType: string;
  isActive: boolean;
  isOnPrem: boolean;
  onPremReportId: number;
  customReportUrl: string;
  constructor() {
    // defaults for new
    this.isActive = false;
    this.showFilterSw = false;
    this.isInNavMenu = false;
  }
}

export class DataGridFilterInfo {
  dataGridFilterId: number;
  dataGridFilterIdDependentOn: number;
  dataGridFilterIdDependentOnIdString: string;
  dataGridId: number;
  dataGridFilterTypeId: number;
  dataGridFilterTypeIdString: string;
  filterType: string;
  displayName: string;
  dataSourceSql: string;
  textField: string;
  valueField: string;
  isMultiSelect: boolean;
  dataGridFilterRangeTypeId: number;
  dataGridFilterRangeTypeIdString: string;
  dataGridReportFilterId: number;
  rangeType: string;
  filterOrder: number;
  filterOrderString: string;

  constructor() {
    // defaults for new
    this.isMultiSelect = false;
  }
}

export interface DataGridFilterType {
  dataGridFilterTypeId: number;
  filterTypeName: string;
  filterTypeDesc: string;
}

export interface TileDateFilterTypeChoice {
  tileDateFilterTypeId: number;
  tileDateFilterTypeName: string;
}

export class DashboardDateFilterRange {
  startDate: string;
  endDate: string;
  rollingTypeId?: number;
}

export interface ClientReqTitles {
  client: string;
  clientName: string;
  req1: string;
  req2: string;
  req3: string;
  req4: string;
  req5: string;
  req6: string;
  req7: string;
  req8: string;
}

export interface ApprovalResponseLookup {
  responseLookupId: number;
  responseDescription: string;
  isCommentMandatory: boolean;
}

export interface AccidentClaimInvoiceChargeCode {
  description: string;
  amount: number;
}

export interface AccidentClaimInvoice {
  claimId: number;
  invoiceNumber: string;
  invoiceDate: string;
  chargeCodes: Array<AccidentClaimInvoiceChargeCode>;
}

export interface AccidentThirdPartyClaimMap {
  thirdPartyClaimMapId: number;
  firstPartyClaimId: number;
  thirdPartyClaimId: number;
  firstPartySystemSource: string;
  linkClaimType: string;
  isLinkActive: boolean;
}

export interface AccidentClaimInfo {
  claimId: number;
  client: string;
  driverName: string;
  dateOfLoss: string;
  dateReported: string;
  closedDate: string;
  invoiceDate: string;
  followUpDate: string;
  req1: string;
  req2: string;
  req3: string;
  req4: string;
  req5: string;
  req6: string;
  req7: string;
  req8: string;
  req1Title: string;
  req2Title: string;
  req3Title: string;
  req4Title: string;
  req5Title: string;
  req6Title: string;
  req7Title: string;
  req8Title: string;
  assignedDriverId: string;
  assignedClaimRep: string;
  assignedClaimRepEmail: string;
  claimRepPhoneNumber: string;
  parameter20: string;
  clientClaimPartyType: string;
  accidentReason: string;
  clientAccidentType: string;
  preventability: string;
  subro: string;
  rental: string;
  locationDescription: string;
  //thirdPartyClaimMap: AccidentThirdPartyClaimMap;
  thirdPartyClaimMap: Array<AccidentThirdPartyClaimMap>;
  driverId: number;
  driverEmail: string;
}

export interface ClaimArbitrationDetail {
  arbitrationId: number;
  globalId: number;
  claimId: number;
  unitId: number;
  insuranceOrgEntityCompanyId: number;
  amountDemanded: number;
  filingDate: string;
  estimatedHearingDate: string;
  materialDueDate: string;
  eSubroHub: boolean;
  closeDate: string;
  closeReasonId: number;
  closeReasonString: string;
  decisionDate: string;
  postDecisionInquiryDate: string;
  postDecisionInquiryReason: string;
  isFleetResponseOverallWin: boolean;
  lastRescheduleDate: string;
  createDate: string;
  createUser: number;
  updateDate: string;
  updateUser: number;
}

export interface ArbitrationDocketDetail {
  docketNumber: string;
  decision: string;
  percentageOfLiability: number;
  status: string;
  amountAwarded: number;
  filingType: string;
  forumType: string;
  role: string;
  name: string;
  amountRequested:number;
  companyName : string;
  previousPaymentReceived:number;
  adjusterEmail : string;
  addres: string;
  respondent:Array<Respondent>;
}

export interface Respondent {
  name: string;
  amountRequested:number;
  companyName : string;
  previousPaymentReceived:number;
  adjusterEmail : string;
  address: string;
}
export interface AccidentClaimActionHistory {
  claimActionId: number;
  claimId: number;
  claimAction: string;
  formattedCreateDate: string;
  createDate: string;
}

export interface AccidentClaimNote {
  claimNoteGuid: string;
  claimNoteId: number;
  claimId: number;
  noteText: string;
  noteTypeHexColor: string;
  createUserName: string;
  formattedCreateDate: string;
}

export interface AccidentClaimRentalDetail {
  rentalId: number;
  claimId: number;
  rateType: string;
  dailyRate: number;
  invoiceDays: number;
  rentalAmount: number;
  invoiceStatus: string;
  dateIn: string;
  dateOut: string;
  agency: string;
}

export interface AccidentClaimRepairApprovalDetail {
  repairClientApprovalId: number;
  claimId: number;
  client: string;
  driverName: string;
  dateOfLoss: string;
  vehicle: string;
  vin: string;
  vehicleYear: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleOdometer: number;
  licensePlate: string;
  licensePlateState: string;
  isVehicleDrivable: string;
  preventability: string;
  isSubro: string;
  requestDate: string;
  approvalDescription: string;
  noteToClient: string;
  recommendation: string;
  estimateDate: string;
  estimateAmount: number;
  estimateType: string;
  documentIdentifier: string;
  documentDescription: string;
  clientActionLookupId: number;

  req1: string;
  req2: string;
  req3: string;
  req4: string;
  req5: string;
  req6: string;
  req7: string;
  req8: string;
  userEmailAddress: string;
}

export interface AccidentClaimRepairApprovalHistory {
  repairClientApprovalId: number;
  requestDate: string;
  approvalDescription: string;
  noteToClient: string;
  contactName: string;
  estimateAmount: number;
  responseDate: string;
  responseDescription: string;
  clientComments: string;
}

export interface AccidentClaimRepairDetail {
  claimId: number;
  vendorName: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  faxNumber: string;
  ownerManagerName: string;
  emailAddress: string;
  repairStartDate: string;
  expectedReturnDate: string;
  repairCompleteDate: string;
  actualRepairDays: number;
  taxAmount: number;
  towAmount: number;
  totalAmount: number;
  reserveAmountLabel: string;
  reserveAmount: number;
  reserveAmount2Label: string;
  reserveAmount2: number;
  reserveAmount3Label: string;
  reserveAmount3: number;
  claimStatus: string;
}

export interface AccidentClaimRepairEstimateDetail {
  claimEstimatePoId: number;
  claimId: number;
  estimateDate: string;
  approveDate: string;
  estimateAmount: number;
  estimateType: string;
}

export interface AccidentClaimRepairGlassDetail {
  claimId: number;
  glassAmount: number;
  glassDate: string;
}

export interface AccidentClaimRepairPayoutDetail {
  claimVendorDetailId: number;
  payoutAmount: number;
  payoutDate: number;
  payeeName: number;
}

export class AccidentClaimSupplementalData {
  claimId: number;
  receivedAmount: number;
  estimateAmount: number;
  deductibleAmount: number;
  towingAmount: number;
  storageAmount: number;
  rentalExpenseAmount: number;
  rentalReimbursementAmount: number;
  salvageAmount: number;
  glassPaymentAmount: number;
  nbv: number;
  merchantsPaidDate: string;
  merchantsPaidAmount: number;
  isTotalLoss: boolean;
  merchantsLiabilityId: number;
  merchantsLiabilityIdString: string;
  liabilityClaimNumber: string;
  deductibleReceivedAmount: number;
  policeReportExpenseAmount: number;
  merchantsLeaseTypeId: number;
  merchantsLeaseTypeIdString: string;
  miscReceivedAmount: number;
  miscExpenseAmount: number;
  bodilyInjuryClaimNumber: string;

  constructor() {
    return {
      claimId: 0,
      receivedAmount: 0.00,
      estimateAmount: 0,
      deductibleAmount: 0,
      towingAmount: 0,
      storageAmount: 0,
      rentalExpenseAmount: 0,
      rentalReimbursementAmount: 0,
      salvageAmount: 0,
      glassPaymentAmount: 0,
      nbv: 0,
      merchantsPaidDate: null,
      merchantsPaidAmount: 0,
      isTotalLoss: false,
      merchantsLiabilityId: null,
      merchantsLiabilityIdString: '',
      liabilityClaimNumber: null,
      deductibleReceivedAmount: 0,
      policeReportExpenseAmount: 0,
      merchantsLeaseTypeId: null,
      merchantsLeaseTypeIdString: '',
      miscReceivedAmount: 0,
      miscExpenseAmount: 0,
      bodilyInjuryClaimNumber: null
    }
  }
}

export interface AccidentClaimVehicle {
  unitId: number;
  claimId: number;
  unitNumber: string;
  vin: string;
  vehicleYear: number;
  vehicleMake: string;
  vehicleModel: string;
  licensePlate: string;
  licensePlateState: string;
  vehicleOdometer: number;
  damageDescription: string;
  unitPriority: string;
  currentLocation: string;
}

export interface AccidentClaimSubroApprovalDetail {
  subroClientApprovalId: number;
  claimId: number;
  client: string;
  vehicle: string;
  dateOfLoss: string;
  startDate: string;
  closeDate: string;
  subroStatus: string;
  subroRepUserId: number;
  subroRepName: string;
  subroRepEmailAddress: string;
  damageDescription: string;
  actionRequested: string;
  requestDate: string;
  noteToClient: string;
  recommendation: string;
  subroLiabilityStatus: string;
  insuranceStatus: string;
  liabilityPercentage: number;
  liabilityStatusReason: string;
  stateNegligenceLaw: string;
  clientActionLookupId: number;
}

export interface AccidentClaimSubroApprovalHistory {
  subroClientApprovalId: number;
  requestDate: string;
  approvalDescription: string;
  noteToClient: string;
  contactName: string;
  responseDate: string;
  responseDescription: string;
  clientComments: string;
}

export interface AccidentClaimSubroInboundInfo {
  cLaimId: number;
  client: string;
  vehicle: string;
  claimClosedDate: string;
  subroStartDate: string;
  subroClosedDate: string;
  subroFollowupDate: string;
  subroStatus: string;
  subroRepUserId: number;
  subroRep: string;
  subroRepEmail: string;
  subroRepPhoneNumber: string;
  inboundSubroClaim: number;
  liabilityPercentage: number;
  deductibleAmount: number;
  deductiblePrepaid: string;
  originalDemandAmount: number;
  totalDemandLessDeductibleAmount: number;
  settlementAmount: number;
  totalFeeAmount: number;
  globalId: string;
  totalDemandAmount: number;
}

export interface AccidentClaimSubroOutboundInfo {
  claimId: number;
  client: string;
  vehicle: string;
  dateOfLoss: string;
  claimClosedDate: string;
  subroStartDate: string;
  subroClosedDate: string;
  subroFollowupDate: string;
  subroStatus: string;
  subroRepUserId: number;
  subroRep: string;
  subroRepEmail: string;
  subroRepPhoneNumber: string;
}

export interface AccidentClaimSubroNetRecoveryInfo {
  category: string;
  requestedAmount: number;
  receivedAmount: number;
  customerNetRecoveryAmount: number;
}

export interface AccidentClaimSubroStatus {
  claimId: number;
  status: string;
  createDate: string;
}

export interface AccidentClaimTotalLossBidHistory {
  bidOrder: number;
  bidAmount: number;
  bidDateTime: string;
}

export interface AccidentClaimTotalLossDetail {
  salvageId: number;
  claimId: number;
  unitId: number;
  vehicle: string;
  vehicleYear: number;
  vehicleMake: string;
  vehicleModel: string;
  vehicleOdometer: string;
  vehicleLicensePlate: string;
  vehicleLicensePlateState: string;
  vin: string;
  vehicleLocation: string;
  vehicleLocationAddress: string;
  vehicleLocationCity: string;
  vehicleLocationState: string;
  titleSentDate: string;
  finalSaleAmount: number;
  titleReceiveDate: string;
  actualCashValue: number;
  titleStatus: number;
  soldDate: string;
  netSalvageReturn: number;
}

export interface AccidentClaimAtFaultPartyInfo {
  claimId: number;
  client: string;
  atFault: string;
  insuranceCompany: string;
  addressLine1: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  phoneNumberExtension: string;
  faxNumber: string;
  adjuster: string;
  emailAddress: string;
  insuranceCompanyClaimId: string;
  subroLiabilityStatus: string;
  liabilityStatusReason: string;
  liabilityStatusPercent: number;
  rental: string;
  subro: string;
  subroRecoverySource: string;
  //arbitration: AccidentClaimAtFaultArbInfo;
  arbFilingDate: string;
  arbHearingDate: string;
  arbCloseDate: string;
  arbRequestedAmount: number;
  arbCollectedAmount: number;
  arbDocket: string;
  outsideSubro: AccidentClaimAtFaultOutsideSubroInfo;
}

export interface AccidentClaimAtFaultOutsideSubroInfo {
  outsideSubroId: number;
  claimId: number;
  claimType: string;
  agencyId: number;
  agencyName: string;
  startDate: string;
  closeDate: string;
  oaClaim: string;
  inSuit: string;
  suitFiledDate: string;
  judgmentDate: string;
}

/* 2019-10 - properties moved to AccidentClaimAtFaultPartyInfo
export interface AccidentClaimAtFaultArbInfo {
    claimId: number;
    arbFilingDate: string;
    arbHearingDate: string;
    arbCloseDate: string;
    arbRequestedAmount: number;
    arbCollectedAmount: number;
    arbDocket: string;
}
*/

export interface CorporateStructure {
  elementGuid: string;
  element: string;
  tier: number;
  hasChildren: boolean;
  isDriver: boolean;
  isLowestTier: boolean;
  driverId: number;
  isSelected: number;
  childElements: Array<CorporateStructure>;
  // reference back to parent element
  parentElement: CorporateStructure;
  // boxChecked and indeterminate indicates checkbox state in UI
  boxChecked: boolean;
  indeterminate: boolean;
  // flag to indicate that the element was updated
  // via user click action
  updatedByUser: boolean;
}

export interface SalvageTitleHolder {
  titleHolderId: number;
  titleHolderName: number;
  titleHolderContact: number;
}

export interface StateProvince {
  stateProvinceId: number;
  stateCode: string;
  stateName: string;
  countryId: number;
  country: string;
}

export interface SupportsDocInfo {
  documentGuid: string;
  azureBlobContainerName: string;
  AzureBlobThumbnailContainerName: string;
  fileName: string;
  mimeType: string;
  documentDesc: string;
  fileSize: string;
  uploadDate: string;
  uploadUser: string;
  thumbnailFilename: string;
  largeFileSize: string;
  userFileName: string;
  azureStorageAccountName: string;
}

export interface UserRightsInfo {
  userRightId: number;
  userRightName: string;
  permission: string;
  lineOfBusinessId: number;
}

export interface AdminNavItem {
  adminMenuItemId: number;
  route: string;
  label: string;
  labeltemplate: string;
  labeltoken: string;
  sortOrder: string;
}

export interface Employee {
  employees: number;
  nonEmployees: number;
}

// *** CUSTOM REPORT BUILDER START ***

export interface CustomReportField {
  sourceColumnId: number;
  sourceColumn: string;
  displayOrder: number;
}

export interface CustomReportHeader {
  "lineOfBusinessId": number;
  "reportId": number;
  "reportName": string;
  "isActive": boolean;
}

export interface CustomReportBody {
  "reportId": number;
  "sourceColumnIds": string;
}

export interface ClientReqFields {
  client: string;
  clientName: string;
  req1: string;
  req2: string;
  req3: string;
  req4: string;
  req5: string;
  req6: string;
  req7: string;
  req8: string;
}

export interface CustomReport {
  value: string;
  label: string;
}

export interface GetLOB {
  reportId: number;
  reportName: string;
}

// *** CUSTOM REPORT BUILDER END ***

export class DriverHistoryProfileModal {
  title: string;
  message: string;
  modalHeight: string;
  modalWidth: string;
  modalInput: any;
}

// *** DRIVER HISTORY PROFILE START ***

export interface DriverProfile {
  blockDriverEdit: boolean;
  clientCode: string;
  clientName: string;
  country: string;
  dataVerificationAssignDate: string;
  dataVerificationCompleteDate: string;
  dataVerificationDueDate: string;
  dateOfBirth: Date;
  dotDriver: boolean;
  driverCreateDate: string;
  driverCreateUser: string;
  driverDuration: number;
  driverId: number;
  driverLicenseExpirationDate: string;
  driverLicenseNumber: string;
  driverLicenseState: string;
  driverLicenseStatus: string;
  driverReactivateDate: string;
  emailAddress: string;
  employeeId: string;
  esigIncomplete: boolean;
  firstName: string;
  fullHierarchy: string;
  fullName: string;
  groupGuid: string;
  hasPendingMvr: boolean;
  hireDate: Date;
  isActive: boolean;
  isCommercialDriversLicense: boolean;
  isEmployee: boolean;
  isLeaveOfAbsencePending: boolean;
  isOnLeaveOfAbsence: boolean;
  isTemporaryDriver: boolean;
  languageId: number;
  language: string;
  lastEsignatureEmailDate: string;
  lastName: string;
  leaveOfAbsenceStartDate: string;
  leaveOfAbsenceEndDate: string;
  leaveOfAbsenceStatus: number;
  livesWithPrimaryDriver: boolean;
  middleName: string;
  isEnrolledInMonitoring: boolean;
  monitoringProgramStatus: string;
  monitoringStartDate: string;
  monitoringLastUpdateDate: string;
  mvrRequestDate: string;
  primaryDriverId: number;
  primaryDriverDetails: PrimaryDriverDetails;
  primaryDriverRelationshipId: number;
  req1: string;
  req10: string;
  req2: string;
  req3: string;
  req4: string;
  req5: string;
  req6: string;
  req7: string;
  req8: string;
  req9: string;
  riskLevel: string;
  riskLevelColor: string;
  temporaryDriverExpirationDate: string;
  trainingOnlyPortalAccess: boolean;
  trainingOnlyPortalUserName: string;
  trainingOnlyPortalPassword: string;

  mvrTaskStatus: string;
  mvrTaskStatusColor: string;
  isMvrTaskPending: boolean;
  licenseVerificationStatus: string;
  licenseVerificationStatusColor: string;
  isLicenseVerificationPending: boolean;

  // added by component code
  //driverFirstLastName: string;
  followUpDate: string;
  primaryDriverFirstLastName: string;

  // collections
  auxiliaryPointHistory: Array<AuxiliaryPointHistory>;
  completedTraining: Array<DriverTraining>;
  currentMvr: MVRRecordsCurrent;
  mvrHistory: Array<MVRHistory>;
  driverDocuments: Array<DriverDocuments>;
  driverEmploymentHistory: Array<DriverEmploymentHistory>;
  driverAutoCoverage: Array<DriverAutoCoverage>
  driverNotes: Array<DriverNotes>;
  MotorMindsAssignments: Array<DriverMMTraining>;
  driverQualifications: Array<DriverQualifications>;
  driverTelematics: Array<DriverTelematics>;
  incompleteTraining: Array<DriverTraining>;
  mvrViolations: Array<MVRViolationsCurrent>;
  riskHistory: Array<RiskHistory>;
  riskClaimHistory: Array<RiskClaimHistory>;
  secondaryDrivers: Array<DriverProfile>;

  hpeAssignments: Array<DriverHPEAssignment>;
  hpeCoreCompetencies: Array<DriverHPECoreCompetency>;
  hpeSubLessons: Array<DriverHPESubLesson>;

  externalTraining: Array<DriverTraining>;

  pendingTasks: Array<DriverPendingTask>;

  // flags to indicate whether data arrays have loaded
  currentMvrLoaded: boolean;
  mvrHistoryLoaded: boolean;
  mvrViolationsLoaded: boolean;
  driverDocumentsLoaded: boolean;
  driverNotesLoaded: boolean;
  completedTrainingLoaded: boolean;
  incompleteTrainingLoaded: boolean;
  driverTelematicsLoaded: boolean;
  riskHistoryLoaded: boolean;
  riskClaimHistoryLoaded: boolean;
  auxiliaryPointHistoryLoaded: boolean;
  driverQualificationsLoaded: boolean;
  secondaryDriversLoaded: boolean;
  driverEmploymentHistoryLoaded: boolean;
  hpeAssignmentsLoaded: boolean;
  hpeCoreCompetenciesLoaded: boolean;
  hpeSubLessonsLoaded: boolean;
  externalTrainingLoaded: boolean;
  pendingTasksLoaded: boolean;
  motormindsAssignmentsLoaded: boolean;
  requiresAutoCoverage: boolean;

  homeState: string;
}

export class DriverProfileTabInfo {
  description: string;
  id: number;
  secondaryId: number;
  secondaryCode: string;
}

export interface MVRRecordsCurrent {
  mvrId: string;
  driverGuid: string;
  completionDate: string;
  //licenseNumberState: string;
  violations: Array<MVRViolationsCurrent>;
  licenseNumber: string;
  licenseState: string;
  licenseStatus: string;
  licenseClass: string;
  requestedUserName: string;
  currentMvrStatus: string;
  mvrSource: string;
}

export interface MVRHistory {
  driverId: number;
  mvrId: string;
  receivedDate: string;
  licenseNumber: string;
  licenseState: string;
  licenseStatus: string;
  licenseClass: string;
  requestedUserName: string;
  mvrStatus: string;
  mvrSource: string;
  isContestable: boolean;
  contestedDate: string;
  verifiedDate: string;
  contestedEnabled: boolean;
}

export interface MVRViolationsCurrent {
  incidentId: number;
  violationDate: string;
  masterDescription: string;
  incidents: number;
  totalPoints: number;
}

export interface DriverDocuments {
  driverDocumentId: number;
  driverGuid: string;
  dateFormUploaded: string;
  uploadedBy: string;
  documentDescription: string;
  dateFormRejectedApproved: boolean;
  formReview: string;
  isFormReviewRequired: boolean;
  fileExtension: string;
}

export interface DriverNotes {
  driverNoteId: number;
  note: string;
  enteredBy: string;
  driverEmailOutboxId: number;
}

export interface DriverPendingTask {
  foreignId: number;
  driverId: number;
  driverType: string;
  task: string;
  trainingProvider: string;
  taskStatus: string;
  processStatus: string;
  assignedBy: string;
  dueDate: string;
}

export interface DriverTraining {
  assignedBy: string;
  assignmentId: number;
  attempts: number;
  courseId: number;
  dateAssigned: string;
  dateCompleted: string;
  driverId: number;
  dueDate: string;
  highestScore: number;
  lesson: string;
  passRate: number;
  targetedType: string;
}
export interface DriverHPEAssignment {
  assignmentId: number;
  courseTitle: string;
  assignmentStatus: string;
  assignedDate: Date;
  dueDate: Date;
  completeDate: Date;
}
export interface DriverHPECoreCompetency {
  assignmentId: number;
  coreCompetency: string;
  score: number;
}
export interface DriverHPESubLesson {
  assignmentId: number;
  courseTitle: string;
  status: string;
  assignedDate: Date;
  dueDate: Date;
  completeDate: Date;
}

export interface DriverActionMenuLabel {
  description: string;
  id: number;
}

export interface DriverActionMenuItem {
  description: string;
  id: number;
  secondaryId: number;
}



export interface DriverViewEmail {
  driverEmailOutboxSentId: number;
  driverEmailOutboxId: number;
  sendTime: string;
  toRecipients: string;
  ccRecipients: string;
  bcRecipients: string;
  emailBody: string;
}

export class DriverInactive {
  driverId: number;
  employeeId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: Date;
  email: string;
  group: string;
  deactivatedDate: string;
  isGroupActive: boolean;
}

export interface DrivingHistoryReview {
  id: number;
  employerName: string;
  from: string;
  to: string;
  positionName: string;
  drivingPosition: boolean;
  attempt1: string;
  attempt2: string;
  attempt3: string;
  verified: boolean;
}

export interface DriverEmploymentHistory {
  employerName: string;
  employerPhone: string;
  startDate: string;
  endDate: string;
  positionHeld: string;
  isDrivingPosition: boolean;
  contactAttemptCount: number;
  isVerified: boolean;
  verificationUnsuccessfulReasonId: number;
}

export interface DriverAutoCoverage {
  documentType: string;
  assignedDate?: string;
  dueDate?: string;
  documentCreateDate?: string;
  status: string;
  statusStyle: string;
  requiresVerification: boolean;
  verifiedDate: string;
  expirationDate: string;
}

export interface MonitoringProgram {
  monitoringStatus: string;
  dateEnrolled: string;
  dateOfMostRecentReview: string;
}

export interface DataVerification {
  assignedDate: string;
  dueDate: string;
  licenseUpdated: string;
}

export class DriverExtendedInfo {
  reqField: string;
  reqDescription: string;
  reqIsRequired: boolean;
  formControl: UntypedFormControl;
}

export class DriverFollowUpDate {
  driverId: number;
  driverFollowUpId: number;
  followUpDate: string;
  noteText: string;
  sendEmail: boolean;

  constructor(driverId: number, followUpDate: string) {
    this.driverId = driverId;
    this.driverFollowUpId = driverId;
    this.followUpDate = followUpDate;
    this.noteText = null;
    this.sendEmail = true;
  }
}

export interface RiskHistory {
  riskId: number;
  changeReason: string;
  dateOfChange: string;
  pointsAssigned: number;
  rating: string;
  totalPoints: number;
  mvrId: string;
  rowColor: string;
  isHyperlink: boolean;
}

export interface RiskClaimHistory {
  riskClaimId: number;
  claimId: number;
  dateOfLoss: string;
  accidentReason: string;
  claimCost: string;
  preventability: string;
  points: number;
  claimIncidentType: string;
}

export interface AuxiliaryPointHistory {
  riskAuxiliaryPointId: number;
  infraction: string;
  points: number;
  dateOfIncident: string;
  expires: string;
  dateEntered: string;
  assignedBy: string;
  lennoxAlertDetailId: number;
}

export interface MVRReport {
  mvrId: string;
  company: string;
  requestedDate: string;
  requestedBy: string;
  completeDate: string;
  mvrStatus: string;
  isExpired: boolean;
  generatedDate: string;
  generatedBy: string;
  provider: string;
  driver: MVRReportDriver;
  driverLicense: MVRReportDriverLicense;
  violations: Array<MVRReportViolation>;
  medicalCertificate?: MedicalCertificate;
  probationInfo: Array<ProbationInfo>;
  returnInfo: Array<ReturnInfo>;
  previousLicenseInfo: Array<PreviousLicenseInfo>;
  addressInfo: Array<AddressInfo>;
  exceptions: Array<Exceptions>;
  orders: Array<Orders>;
  endorsements: Array<Endorsements>;
  additionalLicenses: Array<AdditionalLicenses>;
  additionalMessagesInfo: Array<AdditionalMessagesInfo>;
  financialResponsibilityInfo: Array<FinancialResponsibilityInfo>;
  // placeholders for properties not actually returned
  licenseLegend: string;
  driverRecordLegend: string;
  disclaimer: string;
}

export interface MedicalCertificate {
  expirationDate?: string;
  status?: string;
  selfCertificationType?: string;
  issueDate?: string;
  restrictions?: string;
  examinerFirstName?: string;
  examinerLastName?: string;
  examinerLicenseNumber?: string;
  additionalComments?: string;
}

export interface DriverMMTraining {
  assignmentId: number;
  title: string;
  assignedDate: Date;
  completedDate: Date;
  dueDate: Date;
  status: string;
  assignedBy: string;
}

export interface ProbationInfo {
  mvrId?: number;
  docketNumber: string;
  state: string;
  violationDate?: string;
  terminationDate?: string;
  stateDescription: string;
  createDate?: string;
}

export interface ReturnInfo {
  mVR_ID?: number;
  return_ID?: number;
  valid: string;
  date?: string;
  time: string;
  clear: string;
  originalType: string;
  multiHit: string;
  noHit: string;
  response: string;
  company_Score?: number;
  company_Class: string;
  recordCreateDate?: string;
}

export interface PreviousLicenseInfo {
  mvR_ID: string;
  previousLicense_ID?: number;
  previousNumber: string;
  previousState: string;
  previousClass: string;
  createDate?: string;
}

export interface AddressInfo {
  addressId?: number;
  type: string;
  dhpDriverId?: number;
  asOfDate?: string;
  street: string;
  city: string;
  county: string;
  state: string;
  zip: string;
  createDate?: string;
}

export interface Exceptions {
  mvrId?: number;
  mvrRequestId?: number;
  dhpDriverId?: number;
  response?: number;
  action: string;
  createDate?: string;
  clearDate?: string;
  mvrCreateDate?: string;
  sortOrder?: number;
  isHandled: boolean;
  scoringBatchId?: number;
}

export interface Orders {
  mvrId?: number;
  control: string;
  orderDate?: string;
  orderTime: string;
  orderDOB?: string;
  handling: string;
  misc: number;
  state: string;
  license: string;
  ordersExtended: Array<ExtendedInfo>;
}

export interface ExtendedInfo {
  entityId: number;
  attributeKey: string;
  attributeValue: string;
}

export interface Endorsements {
  endorsementId: number;
  licenseId: number;
  name: string;
  startDate: string;
  ExpirationDate: string;
}

export interface AdditionalLicenses {
  licenseId: number;
  type: string;
  primaryLicense: boolean;
  issueDate?: string;
  expireDate?: string;
  renewalDate?: string;
  origIssueE?: string;
  classCode: string;
  class: string;
  totalStatePoints?: number;
  additionalLicensesExtended: Array<ExtendedInfo>;
}

export interface AdditionalMessagesInfo {
  additonalMessageId?: number;
  mvrId?: number;
  line: string;
  createDate?: string;
}

export interface FinancialResponsibilityInfo {
  licenseId?: number;
  financialResponsibilityId?: number;
  description: string;
  filedDate?: string;
  expirationDate?: string;
  proofRequiredDate?: string;
  proofEndDate?: string;
  createDate?: string;
}

export interface MVRReportDriver {
  driverId: number;
  employeeId: string;
  driverName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullHierarchy: string;
  dateOfBirth: Date;
  mvrRequestId: number;
  totalOrgPoints: string;
  invalidLicenseNotice: string;
  eyeColor: string;
  weight: string;
  hairColor: string;
}

export interface MVRReportDriverLicense {
  licenseNumber: string;
  licenseState: string;
  issueDate: string;
  expirationDate: string;
  licenseClass: string;
  restrictionsAndEndorsements: string;
  licenseStatus: string;
}

export interface MVRReportViolation {
  incidentId: number;
  type: string;
  violationDate: string;
  convictionDate: string;
  masterDescription: string;
  description: string;
  incidents: number;
  totalPoints: number;
  stateFlag: boolean;
  violationsExtended: Array<ExtendedInfo>;
}

export interface DriverTelematics {
  driverTelematicsId: number;
  alertCategory: string;
  alertScore: number;
  hasDetail: boolean;
}

export interface DriverTelematicsDetail {
  driverTelematicDetailId: number;
  startDate: string;
  endDate: string;
  score: number;
}

export interface DriverQualifications {
  processId: number;
  covStatus: string;
  covStatusColor: string;
  documentType: string;
  assignedDate: string;
  dueDate: string;
  driverCompletedDate: string;
  processCompletedDate: string;
  status: string;
}

export interface PrimaryDriverDetails {
  driverId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  employeeId: string;

}

export interface DriverClientSettings {
  allowMultipleNonEmployees: boolean;
  clientName: string;
  clientType: number;
  dataVerification: boolean;
  defaultCountry: number;
  defaultLanguage: number;
  dotServices: boolean;
  driversUseTrainingOnlyPortal: boolean;
  employeeAutoEnrollInMonitoring: boolean;
  hasNonEmployees: boolean;
  hasMultiAccidentTypes: boolean;
  hasExternalTraining: boolean;
  hasTelematics: boolean;
  isAutoCoverageOn: boolean;
  isCertificateOfViolationOn: boolean;

  isDQDriverApplicationOn: boolean;
  isDQDriverHistoryReviewOn: boolean;
  isDQMedCardUploadOn: boolean;
  isDQCertificateOfRoadTestOn: boolean;
  isDQLicenseUploadOn: boolean;
  isDQCertificateOfViolationOn: boolean;
  isDriverAcknowledgementOn: boolean;
  isDriverQualificationOn: boolean;
  isDQDriverLogUploadOn: boolean;
  isDQClearingHouseLimitedSignoffOn: boolean;

  isLicenseUploadOn: boolean;
  isPolicyTask: boolean;
  licenseUploadDueDays: number;
  licenseVerificationDueDays: number;
  mixedTrainingOnlyPortal: boolean;
  mvrAccount: boolean;
  mvrMonitorNonEmployee: boolean;
  mvrMonitorServices: boolean;
  mvrSource: string;
  nonEmployeeAutoEnrollInMonitoring: boolean;
  nonEmployeeMvrUpload: boolean;
  nonEmployeeProfiling: boolean;
  nonEmployeeTraining: boolean;
  nonEmployeesUseTrainingOnlyPortal: boolean;
  profilingAccount: boolean;
  policyTaskDueDays: number;
  riskLevelTypeMaster: number;
  timeClockActive: boolean;
  trainingAccount: boolean;
  trainingDueDays: number;
  hasContestedMvr: boolean;
  hasClientDocumentLibrary: boolean;
}

export class DriverTrainingCategory {
  description: string;
  id: number;
  secondaryCode: number;
  secondaryId: number;
}

export class DriverTrainingCourse {
  courseGroupId: number;
  courseCategoryId: number;
  passRate: number;
  title: string;
}

export class DQFileManagementBaseInfo {
  driverProcessAssignedId: number;
  clientProcessId: number;
  processId: number;
  assignedDate: string;
  dueDate: string;
  driverCompletedDate: string;
  processCompletedDate: string;
  cancelledDate: string;
  driverId: number;

  documentId: number;
  fileName: string;
  uploadDate: string;
  uploadUserId: number;
}

export interface DQFileManagementEmployerInfo {
  driverProcessAssignedId: number;
  clientProcessId: number;
  processId: number;
  assignedDate: string;
  dueDate: string;
  driverCompletedDate: string;
  processCompletedDate: string;
  cancelledDate: string;
  driverId: number;

  employmentHistoryId: number;
  employerName: string;
  employerPhone: string;
  positionHeld: string;
  startDate: string;
  endDate: string;
  isDrivingPosition: boolean;
  contactAttemptCount: number;
  isVerified: boolean;
  verificationUnsuccessfulReasonId: number;
  verificationUnsuccessfulReasonIdString: string;
  verificationUnsuccessfulReason: string;
  isVerificationAttemptComplete: boolean;
  reasonOptions?: any[];
}

// *** DRIVER HISTORY PROFILE END ***

export class UserSettings {
  settings: {
    contactGuid: string,
    firstName: string,
    lastName: string,
    emailAddress: string,
    isMasterAccount: boolean,
    masterClientId: number,
    isActiveFsUser: boolean,
    createUserName: string
  };
  subscriptions: [
    {
      userSubscriptionId: number
    }
  ];
  roles: any[];
  assignments: any[];
}

export class ErrorObject {
  message: string;
  referenceID: string;
  detail: string;
  // modelState gets returned by Driver Profile services
  modelState: Array<any>;
  // title and errors array may get returned for some 400 responses
  title: string;
  errors: Array<any>;
}

export class AlertObject {
  title: string;
  message: string;
  htmlMessage: string;
}

@Pipe({
  name: 'phoneUs'
})
export class PhoneUsPipe implements PipeTransform {

  transform(phoneString: string): string {
    // convert 10 character string to US phone format
    // Check if is not null
    if (!phoneString) {
      return '';
    }

    if (phoneString.length < 10) {
      return phoneString;
    }

    return `(${phoneString.slice(0, 3)})${phoneString.slice(3, 6)}-${phoneString.slice(6)}`;
  }

}

@Pipe({
  name: 'negativeToParensPipe'
})
export class NegativeToParensPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    return value.charAt(0) === '-' ? `(${value.substring(1)})`:  value;
  }
}

@Pipe({
  name: 'safe'
})
export class SafePipe implements PipeTransform {

  constructor(private readonly sanitizer: DomSanitizer) {

  }

  transform(htmlString: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(htmlString);
  }

  getSafeResourceUrl(str: any): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(str);
  }
}


@Pipe({
  name: 'stringReplaceCharsPipe'
})
export class StringReplaceCharsPipe implements PipeTransform {
  transform(value: any, arg1: any, arg2: any): any {
    if (value) {
      return value.split(arg1).join(arg2);
    } else {
      return null;
    }
  }
}


export class BusinessGroupNewsItem {
  lineOfBusinessId: number;
  visibilityNewsId: number;
  title: string;
  newsText: string;
  dialogTitle: string;
  dashboardButtonLabel: string;
  isShowPopup: boolean;
  startDate: string;
  endDate: string;
  constructor() {
    //// defaults for new
  }
}

export interface CompanySelectionObject {
  contractorType: ContractorType;
  deliveryType: DeliveryType;
  fullHierarchy: string;
  billingContactId?: number;
  billingEmailAddress: string;
  id: number;
  description: string;
  groupGuid: string;
  checked?: boolean;
}

export interface ContractorType {
  id: number;
  code: string;
  descrtiption: string;
}

export class DeliveryType {
  id: number;
  code: string;
  description: string;
}

export interface AzureDocument {
  clientDocumentId: number;
  fileName: string;
  fileExtension: string;
  title: string;
  description: string;
  uiSortOrder?: number;
  isVisible: boolean;
  azureStorageAccountName: string; // the storage account the document is stored in
  azureBlobContainerName: string;  // the blob container
  azureFileName: string; // the path to the file in the blob container
  azureSharedAccessSignature: string; // the SAS token
  azureBlobUrl: string; // the url to the file
  azureBlobFullUrl: string; // the full url to the file with the SAS token
}

export interface AzureDocumentDataUpload {
  fileName: string;
  title: string;
  description: string;
  uiSortOrder?: number;
  isVisible: boolean;
}

export interface ClientHasDocumentLibrary {
  clientHasDocument: number;
}

export interface DbDocumentAzureInfo {
  documentGuid: string;
  blobContainer: string;
  fileName: string;
  documentMasterApplicationId: number;
  client: string;
  claimId: number;
  contentType: string;
  fileExtension: string;
  azureStorageAccountName: string;
  azureBlobContainerName: string;
  azureSharedAccessSignature: string;
  azureBlobUrl: string;
  azureBlobFullUrl: string;
}

export class LocateDriver {
  driverId: number;
  clientCode: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  corporateHierarchy?: string;
  matchingValue: string;
  clientName: string;
}

export class DhpHierarchy {
  id: number;
  description: string;
  element: string;
  elementGuid: string;
  dhpClient: string;
}

export class MoveDriver {
  driverId: number;
  elementGuid: string;
}

export interface AssignProductToDriverConfirmObject {
  userFacingMessageDefinitionId: number;
  messageBody: string;
}



export class SearchLineOfBusiness {
  searchColumnId: number;
  lineOfBusinessId: number;
  clientCode: string;
  searchText: string;
}

export enum DriverType {
  Employee = 1,
  NonEmployee = 2
}

export enum MvrOrderType {
  Monitored = 1,
  NonMonitored = 2
}

export function minimumCheckboxesSelected(minRequired = 1): ValidatorFn {
  return function validate(fg: UntypedFormGroup) {
    let checked = 0;
    Object.keys(fg.controls).forEach(key => {
      const control = fg.controls[key];
      if (control.value === true) {
        checked++;
      }

    });
    if (checked < minRequired) {
      return {
        requireCheckboxToBeChecked: true,
      };
    }
    return null;
  };
}

export enum SafetyUserRightsList {
  AssignTraining = 18,
  OrderMVRs = 49,
  BatchTool = 51
}
