<div class="row">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          Vehicle:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{subroInfo?.vehicle}}
        </div>
      </div>
      <div class="row">
        <div class="col-sm-3 accidentClaimTabLabel">
          Status:
        </div>
        <div class="col-sm-9 accidentClaimTabBold">
          {{subroInfo?.subroStatus}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingTop">
        <div class="col-sm-3 accidentClaimTabLabel">
          Start Date:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{subroInfo?.subroStartDate |  date:'M/d/yyyy'}}
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
          Date Closed:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{subroInfo?.subroClosedDate |  date:'M/d/yyyy'}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingBottom">
        <div class="col-sm-3 accidentClaimTabLabel">
          Follow Up Date:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{subroInfo?.subroFollowupDate |  date:'M/d/yyyy'}}
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
          Subro Rep:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          <a href={{getMailToLink(subroInfo)}}>{{subroInfo?.subroRep}}</a>
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
        </div>
        <div class="col-sm-3 accidentClaimTabLabel">
          Subro Rep Phone Number:
        </div>
        <div class="col-sm-3 accidentClaimTabBold">
          {{subroInfo?.subroRepPhoneNumber}}
        </div>
      </div>
      <div class="row accidentClaimTabPaddingTop">
        <div class="col-sm-12">
          <table class="table accidentClaimTable accidentClaimSubroTable">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Requested:</th>
                <th scope="col">Received:</th>
                <th scope="col">Customer Net Recovery:</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let nr of subroNetRecoveryInfo">
                <td class="tableLabel">{{nr.category}}</td>
                <td>{{nr.requestedAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</td>
                <td>{{nr.receivedAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</td>
                <td>{{nr.customerNetRecoveryAmount | currency:'USD':'$':'1.2-2' | negativeToParensPipe}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="subroStatuses?.length > 0">
  <div class="col-md-12">
    <div class="card card-body mt-3">
      <h4 class="card-title text-muted">Subrogation History</h4>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-striped table-bordered">
            <thead>
              <tr class="table-header">
                <th scope="col">Status History</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let stat of subroStatuses">
                <td>{{stat.status}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<app-accident-claim-subro-missing-docs *ngIf="_claimId" [_claimId]="_claimId"></app-accident-claim-subro-missing-docs>
<app-accident-claim-note [noteType]="'Subrogation'" [noteTypeId]="5" [userRights]="userRights"></app-accident-claim-note>
