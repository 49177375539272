import { Component, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorModalService } from '../../components/error-modal/error-modal-service.component';
import { LoadingSpinnerService } from '../../services/loading-spinner-service/loading-spinner.service';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';
import { ConfirmModalGenericComponent } from '../../components/confirm-modal-generic/confirm-modal-generic.component';
import {
  DhpDocumentValidation,
  DhpDocumentValidationReject,
  LookupListItem
} from '../../safety/models';

@Component({
  selector: 'app-dhp-document-validation-reject-modal',
  templateUrl: './dhp-document-validation-reject-modal.component.html',
  styleUrls: ['./dhp-document-validation-reject-modal.component.scss'],
})
export class DhpDocumentValidationRejectModalComponent {
  documentData: DhpDocumentValidation;
  rejectReasons: Array<LookupListItem>;

  form = new FormGroup({
    docRejectionReasonId: new FormControl(null, Validators.required),
    docRejectionReasonOtherText: new FormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly dialogData: {
      documentData: DhpDocumentValidation;
      rejectReasons: Array<LookupListItem>;
    },
    private readonly dialogRef: MatDialogRef<DhpDocumentValidationRejectModalComponent>,
    private readonly dhpDocumentService: DhpDocumentService,
    private readonly errorService: ErrorModalService,
    private readonly loadingSpinnerService: LoadingSpinnerService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar
  ) {
    this.documentData = this.dialogData.documentData;
    this.rejectReasons = this.dialogData.rejectReasons;
  }

  handleRejectReasonChange(event: MatSelectChange): void {
    const formControl = this.form.get('docRejectionReasonOtherText');
    if (event.value === 0) {
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity();
    } else {
      formControl.setValue('');
      formControl.clearValidators();
      formControl.updateValueAndValidity();
    }
  }

  openConfirmationModal(): void {
    const dialogRef = this.dialog.open(ConfirmModalGenericComponent,{
      data: {
        confirmGenericTitle: "Confirm Change",
        message: 'Are you sure you want to reject this document',
        yesLabel: 'Reject',
        noLabel: 'Cancel'
      },
      width: '34em',
      minHeight: '10em',
      panelClass: 'alert-modal',
      disableClose: true,
      restoreFocus: false,
      hasBackdrop: true,
      position: { top: '2em' }
    });

    dialogRef.afterClosed().subscribe((result: boolean) =>{
      if (result) {
        this.submit();
      }
    });
  }

  cancel(): void {
    this.closeModal();
  }

  private closeModal(): void {
    this.dialogRef.close();
  }

  private submit(): void {
    this.saveDocumentReject().subscribe(
        (data) => {
          this.snackBar.open('DHP Document Rejected', 'Ok', {
            horizontalPosition: 'end',
            verticalPosition: 'top',
            duration: 5000,
            panelClass: 'success-snackbar',
          });
          this.loadingSpinnerService.hide();
          this.router.navigate(['safety/dhpdocumentvalidationgrid']);
        },
        (err: HttpErrorResponse) => {
          this.errorService.setErrorObject(err.error);
          this.loadingSpinnerService.hide();
        }
      );

    this.closeModal();
  }

  private saveDocumentReject(): Observable<any> {
    try {
      return this.dhpDocumentService.SaveDocumentReject(this.documentData.documentId, {
        docRejectionReasonId: this.form.get('docRejectionReasonId').value,
        docRejectionReasonOtherText: this.form.get('docRejectionReasonOtherText').value
      } as DhpDocumentValidationReject);
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }
}
