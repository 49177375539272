import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DhpDocumentService } from '../../services/dhp-document-service/dhp-document.service';
import { DhpDocumentValidationEditModalComponent } from '../dhp-document-validation-edit-modal/dhp-document-validation-edit-modal.component';
import { DhpDocumentValidationRejectModalComponent } from '../dhp-document-validation-reject-modal/dhp-document-validation-reject-modal.component';
import { DhpDocumentValidationRemoveModalComponent } from '../dhp-document-validation-remove-modal/dhp-document-validation-remove-modal.component';
import {
  DhpDocumentLookupLists,
  DhpDocumentValidation,
  LookupListItem
} from '../../safety/models';

import { DhpDocumentValidationApproveModalComponent } from '../dhp-document-validation-approve-modal/dhp-document-validation-approve-modal.component';

import { UtilityTimeService } from '../../services/utility-services/utility-time.service';

@Component({
  selector: 'app-dhp-document-validation-review',
  templateUrl: './dhp-document-validation-review.component.html',
  styleUrls: ['./dhp-document-validation-review.component.scss']
})
export class DhpDocumentValidationReviewComponent implements OnInit {
  readonly CLASS_ALERT : string[] = [ 'document-validation-review-alert' ];

  dhpDocumentLookupLists: DhpDocumentLookupLists;
  documentData = new DhpDocumentValidation();
  documentDataOrig = new DhpDocumentValidation();
  documentTypes: Array<LookupListItem>;
  rejectReasons: Array<LookupListItem>;
  routeId: number;

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dhpDocumentService: DhpDocumentService,
    private readonly dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: string[]) => {
      this.routeId = params['documentId'];
    });

    this.getDocumentData(this.routeId).subscribe(data => {
      if (data) {
        this.documentData = {
          ...data,
          driverDOB: data.driverDOB ?? new Date('0001-01-01T00:00:00')
        };
        this.documentData.documentId = this.routeId;

        this.documentDataOrig = data;
      }
    });

    this.getDocumentLookupLists().subscribe(data => {
      if (data) {
        this.dhpDocumentLookupLists = data
      }
    });
  }

  openDocumentRemove(): void {
    this.createDialogRef(DhpDocumentValidationRemoveModalComponent, {
      documentData: this.documentData
    });
  }

  openDocumentEdit(): void {
    this.createDialogRef(DhpDocumentValidationEditModalComponent, {
      documentData: this.documentData,
      documentTypes: this.dhpDocumentLookupLists.DocumentType
    });
  }
    
  openDocumentReject(): void {
    this.createDialogRef(DhpDocumentValidationRejectModalComponent, {
      documentData: this.documentData,
      rejectReasons: this.dhpDocumentLookupLists.DocRejectionReason
    });
  }

  openDocumentApprove(): void {
    this.createDialogRef(DhpDocumentValidationApproveModalComponent, {
      documentData: this.documentData
    });
  }

  exit(): void {
    this.router.navigate(['safety/dhpdocumentvalidationgrid']);
  }

  private createDialogRef(t: any, d: any): any {
    return this.dialog.open(t, {
      data: d,
      width: '34em',
      minHeight: '10em',
      panelClass: 'alert-modal',
      position: {
        top: '2em'
      },
      disableClose: true,
      restoreFocus: false
    });
  }

  private getDocumentData(documentId: number): Observable<DhpDocumentValidation> {
    try {
      return this.dhpDocumentService.GetDocument(documentId);
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  private getDocumentLookupLists(): Observable<DhpDocumentLookupLists> {
    try {
      return this.dhpDocumentService.GetDocumentLookupLists();
    }
    catch (err) {
      console.log(err);
      return new Observable<null>();
    }
  }

  public alertIsDriverUnderAge() : Array<string> { return UtilityTimeService.isAgeLessThan18(new Date(this.documentDataOrig.driverDOB ?? new Date())) ? this.CLASS_ALERT : [ ]; }
  public alertIsDocumentPastDue() : Array<string> { return UtilityTimeService.isDaysDifferenceGtOrEq90(new Date(this.documentData.documentUploadedDate)) ? this.CLASS_ALERT : [ ]; }
}
