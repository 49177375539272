<div *ngIf="showPageSize">
  Items per page:
  <mat-form-field appearance="outline" class="mx-2 small mat-mdc-paginator-page-size-select">
    <mat-select [(value)]="pageSize" (selectionChange)="this.paginator._changePageSize($event.value)">
      <mat-option *ngFor="let size of pageSizeOptions" [value]="size">{{size}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<table mat-table [dataSource]="dataSource"  [trackBy]="identity" class="table table-sm data-table" matSort multiTemplateDataRows (matSortChange)="onSortChange($event)">
  <ng-container *ngFor="let column of tableConfig?.columns">
    <ng-container matColumnDef="{{ column?.name }}">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column?.sort" class="data-table-header">{{ column?.title }}</th>
      <td [class.clickable]="column?.clickable" mat-cell *matCellDef="let row">
        <ng-container *ngIf="!['component'].includes(column?.type)">
          <span (click)="onClick(column, row) "[innerHTML]="render(column, row) | safe"
          (keypress)="onClick(column, row) "[innerHTML]="render(column, row) | safe"></span>
        </ng-container>
        <ng-container *ngIf="column?.type === 'component' && column?.component === 'dqDrivingHistoryVerificationAttemptNotes'">
          <app-dq-driving-history-verification-attempt-notes
            [row]="row"
          ></app-dq-driving-history-verification-attempt-notes>
        </ng-container>
        <ng-container *ngIf="column?.type === 'component' && column?.component === 'dqDrivingHistoryVerificationReasonOptions'">
          <app-dq-driving-history-verification-reason-options
            [row]="row"
          ></app-dq-driving-history-verification-reason-options>
        </ng-container>
        <ng-container *ngIf="column?.type === 'component' && column?.component === 'safetySecondaryDriverActiveSwitch'">
          <app-safety-secondary-driver-active-switch
            [row]="row"
          ></app-safety-secondary-driver-active-switch>
        </ng-container>
        <ng-container *ngIf="column?.type === 'component' && column?.component === 'driverMaintenanceReportKeepSwitch'">
          <app-driver-maintenance-report-keep-switch
            [row]="row"
          ></app-driver-maintenance-report-keep-switch>
        </ng-container>
        <ng-container *ngIf="column?.type === 'component' && column?.component === 'driverMaintenanceReportRemoveSwitchComponent'">
          <app-driver-maintenance-report-remove-switch
            [row]="row"
          ></app-driver-maintenance-report-remove-switch>
        </ng-container>
      </td>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="tableConfig?.expandable" matColumnDef="details">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">Details</th>
    <td mat-cell *matCellDef="let row">
      <span class="row-toggler" (click)="(expandedRow = expandedRow === row ? null : row); $event.stopPropagation()"
      (keypress)="(expandedRow = expandedRow === row ? null : row); $event.stopPropagation()">
        <i class="fa fa-bars"></i>
      </span>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container *ngIf="tableConfig?.expandable" matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
      <div [@detailExpand]="row == expandedRow ? 'expanded' : 'collapsed'">
        @if (tableConfig?.expand?.type === 'dhpMvrCustomRowComponent') {
          <app-dhp-mvr-custom-row
            [data]="row"
          ></app-dhp-mvr-custom-row>
        }
      </div>
    </td>
  </ng-container>

  <!-- Table Cell Footer -->
  <ng-container *ngIf="tableConfig?.withFooter">
    <ng-container *ngIf="tableConfig?.footerObject?.type === 'totals'">
      <ng-container matColumnDef="totalsLabel">
        <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length -1" class="text-end">
          <span class="fw-bold">{{ tableConfig?.footerObject?.label }}:</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="totalsValue">
        <td mat-footer-cell *matFooterCellDef>
          <span class="fw-bold">{{ getTotals(tableConfig?.footerObject?.columnName) }}</span>
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- Table Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <ng-container *ngIf="!tableConfig?.expandable">
    <ng-container *matRowDef="let row; columns: displayedColumns">
      <tr [class]="getRowClass(row)" mat-row></tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="tableConfig?.expandable">
    <tr mat-row *matRowDef="let row; columns: displayedColumns"
      [class.expanded-row]="expandedRow === row"
      (click)="expandedRow = expandedRow === row ? null : row"
      onkeypress="expandedRow = expandedRow === row ? null : row">
    </tr>
  </ng-container>
  <ng-container *ngIf="tableConfig?.expandable">
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-details"></tr>
  </ng-container>
  <!-- Row shown when there is no matching data. -->
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell text-center" [attr.colspan]="displayedColumns?.length">{{ tableConfig?.noRecordsLabel }}</td>
  </tr>
  <ng-container *ngIf="tableConfig?.withFooter">
    <ng-container *ngIf="tableConfig?.footerObject?.type === 'totals'">
      <tr mat-footer-row *matFooterRowDef="['totalsLabel', 'totalsValue']"></tr>
    </ng-container>
  </ng-container>
</table>
<ng-container>
  <mat-paginator 
    appBubblePagination
    [appCustomLength]="dataSource.data.length"
    [pageSizeCustomValue]="pageSize"
    (page)="handlePageEvent($event)"
    [pageSize]="pageSize"
    [hidePageSize]="true"
    [pageSizeOptions]="paginatorConfig?.pageSizeOptions"
    [ngClass]="{ hidden: !tableConfig.showPagination}"
  ></mat-paginator>
</ng-container>
